import React from 'react';
import { Col, Row } from 'react-grid-system';
import speakerR from '../../static/images/Speakers/IMG_0809.jpg';
import speakerS from '../../static/images/Speakers/IMG_2397.jpg';
import speakerK from '../../static/images/Speakers/IMG_20191212.jpg';
import speakerA from '../../static/images/Speakers/IMG_123.jpg';

const Speakers = [
  {
    name: `rudrax Variya`,
    SPECIALIZATION: `React JS, Next JS, Gatsby JS  Redux, Node JS, MongoDB, Cypress`,
    PRONOUNS: ` HE/HIM`,
    img: speakerR,
  },
  {
    name: `Sanket Amin`,
    SPECIALIZATION: `PHP, Laravel, Node JS, Javascript, Jquery, MySQL`,
    PRONOUNS: ` HE/HIM`,
    img: speakerS,
  },
  {
    name: `Kalyani Modi`,
    SPECIALIZATION: `Android , Kotlin , React Native ,Flutter Basic`,
    PRONOUNS: ` SHE/HER`,
    img: speakerK,
  },
  {
    name: `Asmeeta Rathod`,
    SPECIALIZATION: ` Mobile App Development, React Native, Flutter Basic, JavaScript`,
    PRONOUNS: ` SHE/HER`,
    img: speakerA,
  },
];

const EventSpeakers = () => (
  <div>
    <div className="divider_curve_main">
      <div className="divider-three">
        <div className="garnish garnish-short"></div>
        <div className="garnish garnish-short"></div>
        <div className="garnish garnish-long"></div>
      </div>
      <div className="long"></div>
      <div className="angled_wrapper">
        <div className="angled"></div>
      </div>
      <div className="short"></div>
    </div>

    <a className="number">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H22C22.8284 0.5 23.5 1.17157 23.5 2V22C23.5 22.8284 22.8284 23.5 22 23.5H8.82843C8.4306 23.5 8.04907 23.342 7.76777 23.0607L0.939339 16.2322C0.658035 15.9509 0.5 15.5694 0.5 15.1716V2Z"
          stroke="currentColor"
        ></path>
      </svg>
    </a>

    <h2 className="title is-2 is-spaced register">SPEAKERS</h2>

    <p className="title-shadow mb-5">
      OPEN SOURCE EXPERTS AND COMMUNITY LEADERS ARE ALL IN ON HACKTOBERFEST.
      FIND THEM HELPING CONTRIBUTORS COMPLETE THEIR PULL/MERGE REQUESTS ALL
      MONTH LONG IN EVENTS THROUGHOUT OCTOBER.
    </p>
    {Speakers.map((speaks, index) => {
      return (
        <div className="divider-main" key={index}>
          <div className="colored-divider">
            <Row className="img-2ndchild">
              <Col xl={2}>
                <img src={speaks.img} className="event-speaker"></img>
              </Col>
              <Col>
                <h3 className="title-shadow">{speaks.name}</h3>
                <ul className="list-style-type-none ul-inline">
                  <li className="mr-4">
                    <span className="text-normal">PRONOUNS:</span>
                    {speaks.PRONOUNS}
                  </li>
                  <li>
                    <span className="text-normal">SPECIALIZATION:</span>
                    {speaks.SPECIALIZATION}
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      );
    })}
  </div>
);

export default EventSpeakers;
