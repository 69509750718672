import React from 'react';
import homeBanner from '../../static/images/Email Banners/Email Banners-Dark.jpg';

const Header = () => (
  <header>
    <nav className="navbar" id="navbar">
      <img id="header-logo" src={homeBanner} alt="Hacktoberfest Logo" />
    </nav>
  </header>
);

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
