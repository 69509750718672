import PropTypes from 'prop-types';
import React from 'react';
import { CookieConsent } from 'react-cookie-consent';
import Header from './Header';
import './layout.css';

function Layout(props: { children: any }) {
  const { children } = props;
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="[ OK ]"
        cookieName="hacktoberfest_wmt"
        containerClasses="cookie-content"
        buttonClasses="cookie-btn"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
      >
        {`>`} We use cookies to provide enhance the user experience. By
        continuing to browse our website, you agree to our use of&nbsp;cookies.
      </CookieConsent>
      <div className="top-bg">
        <Header siteTitle="Hacktoberfest Open Hack Day" />
        <main className="main-img" id="slider" data-set="0">
          {children}
        </main>
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
