import CodeOfConduct from '@/components/CodeOfConduct';
import EventDetail from '@/components/EventDetail';
import EventMap from '@/components/EventMap/EventMap';
import HacktoberFest from '@/components/HacktoberFest';
import HappyMoment from '@/components/HappyMoments';
import Layout from '@/components/Layout';
import RulePrize from '@/components/RulePrize';
import ScheduleEvent from '@/components/ScheduleEvent';
import WMTLogo from '@/components/WMTLogo';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { graphql } from 'gatsby';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventSpeakers from '@/components/EventSpeakers';
import OgImage from '@/static/images/EmailBanners-Dark.png';
import SEO from '../components/SEO';

function IndexPage(props: any) {
  const redirectMap = () => {
    window.open(`https://goo.gl/maps/3pV3bHpLV8owx4ER8`);
  };
  return (
    <>
      <SEO OgImage={OgImage} />
      <Layout>
        <Container>
          <Row
            style={{
              justifyContent: `center`,
              alignItems: `center`,
              height: `100vh`,
            }}
            id="register"
          >
            <Col xxl={6} xl={8} className="full-width">
              <div
                style={{
                  flexDirection: `column`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                <h1 className="title register">Hacktoberfest</h1>
                <h2 className="title-shadow">Online at </h2>
                {` `}
                <h2
                  className="title-shadow"
                  style={{ display: `block`, fontSize: `28px` }}
                >
                  WebMobTech Solutions Pvt. Ltd.
                </h2>
                <a
                  href="https://hacktoberfest.com/register"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-special register-btn button">
                    Register
                  </button>
                </a>
              </div>
            </Col>
            <Col xxl={4} xl={4}>
              <div id="icon" className="annimation">
                <svg
                  width="480"
                  height="120"
                  viewBox="0 0 480 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M78 12H81V15H78V12Z" fill="#B4FF39"></path>
                  <path
                    d="M84 18V21H93V24H84V27H93V30H102V27H93V24H102V21H93V18H84Z"
                    fill="#B4FF39"
                  ></path>
                  <path d="M81 39H78V18H81V39Z" fill="#B4FF39"></path>
                  <path d="M78 42H81V51H78V42Z" fill="#B4FF39"></path>
                  <path d="M198 9H201V12H198V9Z" fill="#B4FF39"></path>
                  <path d="M201 15H198V36H201V15Z" fill="#B4FF39"></path>
                  <path d="M198 39H201V48H198V39Z" fill="#B4FF39"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M204 15V18H213V21H204V24H213V27H219V30H222V27H219V24H222V21H219V18H213V15H204ZM219 21V24H213V21H219Z"
                    fill="#B4FF39"
                  ></path>
                  <path d="M318 6H321V9H318V6Z" fill="#B4FF39"></path>
                  <path d="M321 12H318V33H321V12Z" fill="#B4FF39"></path>
                  <path d="M318 36H321V45H318V36Z" fill="#B4FF39"></path>
                  <path
                    d="M324 12V15H333V18H324V21H333V18H342V15H333V12H342V9H333V12H324Z"
                    fill="#B4FF39"
                  ></path>
                  <path d="M438 15H441V18H438V15Z" fill="#B4FF39"></path>
                  <path d="M441 21H438V42H441V21Z" fill="#B4FF39"></path>
                  <path d="M438 45H441V54H438V45Z" fill="#B4FF39"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M444 21V24H453V27H444V30H453V27H459V24H462V21H459V18H462V15H459V18H453V21H444ZM453 21H459V24H453V21Z"
                    fill="#B4FF39"
                  ></path>
                  <path d="M57 18H60V21H57V18Z" fill="currentColor"></path>
                  <path d="M57 21V24H54V21H57Z" fill="currentColor"></path>
                  <path d="M63 24H60V21H63V24Z" fill="currentColor"></path>
                  <path d="M66 27H63V24H66V27Z" fill="currentColor"></path>
                  <path d="M66 33V27H69V33H66Z" fill="currentColor"></path>
                  <path
                    d="M45 72V69H42V66H45V60H48V72H45Z"
                    fill="currentColor"
                  ></path>
                  <path d="M45 42H42V39H45V42Z" fill="currentColor"></path>
                  <path d="M39 42H42V45H39V42Z" fill="currentColor"></path>
                  <path d="M39 42V39H36V42H39Z" fill="currentColor"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M69 45V36H66V33H63V39H60V36H51V33H45V30H42V27H39V24H24V27H21V30H18V33H15V42H18V48H21V51H24V54H18V57H15V51H12V48H9V51H12V57H15V60H18V57H24V60H30V57H39V54H45V57H42V60H36V63H24V66H21V69H18V72H15V78H18V81H15V84H18V81H21V78H18V72H21V69H33V72H42V75H36V78H30V81H27V84H24V90H27V93H33V90H36V93H39V90H36V87H33V90H27V84H45V81H48V78H51V75H54V81H51V90H54V93H57V96H60V99H66V96H75V102H69V105H66V108H69V105H75V102H78V96H75V93H69V90H63V75H66V72H69V75H72V78H93V84H87V81H84V84H87V87H93V84H96V78H93V75H87V72H93V69H96V66H105V63H108V57H111V54H108V51H105V45H108V48H111V45H108V42H105V45H102V51H105V54H108V57H102V60H93V63H84V60H81V54H78V45H75V48H72V45H69ZM69 45H60V42H57V39H51V36H45V33H42V30H39V27H24V30H21V33H18V42H21V48H24V51H27V54H39V51H45V54H48V57H45V60H42V63H36V66H39V69H42V72H45V75H42V78H36V81H45V78H48V75H51V72H54V75H57V81H54V90H57V93H60V96H66V93H63V90H60V66H63V63H75V66H78V69H93V66H84V63H81V60H78V54H75V51H72V48H69V45Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M84 33H81V36H84V39H87V36H84V33Z"
                    fill="currentColor"
                  ></path>
                  <path d="M81 51V42H84V51H81Z" fill="currentColor"></path>
                  <path d="M75 39H81V42H75V39Z" fill="currentColor"></path>
                  <path d="M75 36V39H72V36H75Z" fill="currentColor"></path>
                  <path d="M75 36V33H78V36H75Z" fill="currentColor"></path>
                  <path
                    d="M165 72V69H162V66H165V60H168V72H165Z"
                    fill="currentColor"
                  ></path>
                  <path d="M162 39H165V42H162V39Z" fill="currentColor"></path>
                  <path d="M159 42H162V45H159V42Z" fill="currentColor"></path>
                  <path d="M159 42H156V39H159V42Z" fill="currentColor"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M177 18H174V21H177V24H183V27H186V33H183V39H180V36H171V33H165V30H162V27H159V24H144V27H141V30H138V33H135V42H138V48H141V51H144V54H138V57H135V54H132V51H129V48H126V51H129V54H132V57H135V60H138V57H144V60H150V57H159V54H165V57H162V60H156V63H144V66H141V69H138V72H135V78H132V81H129V84H132V81H135V78H138V72H141V69H153V72H162V75H156V78H150V81H147V84H144V90H147V93H150V96H153V99H159V96H153V93H150V90H147V84H165V81H168V78H171V75H174V81H171V90H174V93H177V96H180V99H186V96H195V102H198V108H195V111H198V108H201V102H198V96H195V93H189V90H183V75H186V72H189V75H192V78H213V84H216V87H219V90H216V93H219V90H222V87H219V84H216V78H213V75H207V72H213V69H216V66H225V63H228V57H231V51H228V45H231V42H234V39H231V42H228V45H225V51H228V57H222V60H213V63H204V60H201V54H198V42H195V45H189V36H186V33H189V27H186V24H183V21H177V18ZM189 45H180V42H177V39H171V36H165V33H162V30H159V27H144V30H141V33H138V42H141V48H144V51H147V54H159V51H165V54H168V57H165V60H162V63H156V66H159V69H162V72H165V75H162V78H156V81H165V78H168V75H171V72H174V75H177V81H174V90H177V93H180V96H186V93H183V90H180V66H183V63H195V66H198V69H213V66H204V63H201V60H198V54H195V48H189V45Z"
                    fill="currentColor"
                  ></path>
                  <path d="M201 30H204V33H201V30Z" fill="currentColor"></path>
                  <path d="M204 33H207V36H204V33Z" fill="currentColor"></path>
                  <path
                    d="M201 36H195V33H198V30H195V33H192V36H195V39H201V48H204V39H201V36Z"
                    fill="currentColor"
                  ></path>
                  <path d="M297 18H300V21H297V18Z" fill="currentColor"></path>
                  <path d="M297 21V24H294V21H297Z" fill="currentColor"></path>
                  <path d="M303 24H300V21H303V24Z" fill="currentColor"></path>
                  <path d="M306 27H303V24H306V27Z" fill="currentColor"></path>
                  <path d="M306 33V27H309V33H306Z" fill="currentColor"></path>
                  <path
                    d="M285 72V69H282V66H285V60H288V72H285Z"
                    fill="currentColor"
                  ></path>
                  <path d="M285 39V42H276V39H285Z" fill="currentColor"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M309 45V36H306V33H303V39H300V36H291V33H285V30H282V27H279V24H264V27H261V30H258V33H255V42H258V48H261V51H264V54H258V57H255V51H252V48H249V51H252V57H255V60H258V57H264V60H270V57H279V54H285V57H282V60H276V63H264V66H261V69H258V72H255V78H258V81H255V84H258V81H261V78H258V72H261V69H273V72H282V75H276V78H270V81H267V84H264V90H267V93H273V90H276V93H279V90H276V87H273V90H267V84H285V81H288V78H291V75H294V81H291V90H294V93H297V96H300V99H306V96H315V102H309V108H312V105H315V102H318V96H315V93H309V90H303V75H306V72H309V75H312V78H333V84H327V81H324V84H327V87H333V84H336V78H333V75H327V72H333V69H336V66H345V63H348V57H351V54H348V51H345V45H348V48H351V45H348V42H345V45H342V51H345V54H348V57H342V60H333V63H324V60H321V54H318V39H315V45H309ZM309 45H300V42H297V39H291V36H285V33H282V30H279V27H264V30H261V33H258V42H261V48H264V51H267V54H279V51H285V54H288V57H285V60H282V63H276V66H279V69H282V72H285V75H282V78H276V81H285V78H288V75H291V72H294V75H297V81H294V90H297V93H300V96H306V93H303V90H300V66H303V63H315V66H318V69H333V66H324V63H321V60H318V54H315V48H309V45Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M324 27H321V30H324V33H327V30H324V27Z"
                    fill="currentColor"
                  ></path>
                  <path d="M321 33V36H315V33H321Z" fill="currentColor"></path>
                  <path d="M315 30V33H312V30H315Z" fill="currentColor"></path>
                  <path d="M315 30V27H318V30H315Z" fill="currentColor"></path>
                  <path d="M321 36H324V45H321V36Z" fill="currentColor"></path>
                  <path d="M414 18H417V21H414V18Z" fill="currentColor"></path>
                  <path d="M423 24H417V21H423V24Z" fill="currentColor"></path>
                  <path d="M426 27H423V24H426V27Z" fill="currentColor"></path>
                  <path d="M426 33V27H429V33H426Z" fill="currentColor"></path>
                  <path
                    d="M411 36H420V39H423V33H426V36H429V45H420V42H417V39H411V36Z"
                    fill="currentColor"
                  ></path>
                  <path d="M405 33H411V36H405V33Z" fill="currentColor"></path>
                  <path d="M402 30H405V33H402V30Z" fill="currentColor"></path>
                  <path d="M399 27H402V30H399V27Z" fill="currentColor"></path>
                  <path d="M384 27V24H399V27H384Z" fill="currentColor"></path>
                  <path d="M381 30V27H384V30H381Z" fill="currentColor"></path>
                  <path d="M378 33V30H381V33H378Z" fill="currentColor"></path>
                  <path d="M378 42H375V33H378V42Z" fill="currentColor"></path>
                  <path d="M381 48H378V42H381V48Z" fill="currentColor"></path>
                  <path d="M384 51H381V48H384V51Z" fill="currentColor"></path>
                  <path
                    d="M399 54H387V51H384V54H378V57H375V54H372V51H369V48H366V51H369V54H372V57H375V60H378V57H384V60H390V57H399V54Z"
                    fill="currentColor"
                  ></path>
                  <path d="M405 54H399V51H405V54Z" fill="currentColor"></path>
                  <path d="M405 57V54H408V57H405Z" fill="currentColor"></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M405 60V57H402V60H396V63H384V66H381V69H378V72H375V78H372V81H369V84H372V81H375V78H378V72H381V69H393V72H402V75H396V78H390V81H387V84H384V90H387V93H390V96H393V99H399V96H393V93H390V90H387V84H405V81H408V78H411V75H414V81H411V90H414V93H417V96H420V99H426V96H435V102H438V108H435V111H438V108H441V102H438V96H435V93H429V90H423V75H426V72H429V75H432V78H453V84H456V87H459V90H456V93H459V90H462V87H459V84H456V78H453V75H447V72H453V69H456V66H465V63H468V57H471V51H468V45H471V42H474V39H471V42H468V45H465V51H468V57H462V60H453V63H444V60H441V54H444V45H441V42H435V39H438V36H435V39H432V42H435V45H441V54H438V48H435V51H432V48H429V51H432V54H435V57H438V60H441V63H444V66H453V69H438V66H435V63H423V66H420V90H423V93H426V96H420V93H417V90H414V81H417V75H414V72H411V75H408V78H405V81H396V78H402V75H405V72H408V60H405ZM402 69H399V66H396V63H402V60H405V66H402V69ZM402 69H405V72H402V69Z"
                    fill="currentColor"
                  ></path>
                  <path d="M441 36H444V39H441V36Z" fill="currentColor"></path>
                  <path d="M444 39H447V42H444V39Z" fill="currentColor"></path>
                  <path d="M402 39H405V42H402V39Z" fill="currentColor"></path>
                  <path d="M399 42H402V45H399V42Z" fill="currentColor"></path>
                  <path d="M399 42H396V39H399V42Z" fill="currentColor"></path>
                </svg>
              </div>
            </Col>
          </Row>

          <Row justify="center">
            <Col xxl={10} xl={12} className="mt-5 event-detail" id="detail">
              <EventDetail />
            </Col>

            <Col xxl={10} xl={12} className="mt-5">
              <ScheduleEvent />
            </Col>

            <Col xxl={10} xl={12} className="mt-5">
              <EventSpeakers />
            </Col>

            <Col xxl={10} xl={12} className="mt-5">
              <HacktoberFest />
              <div className="divider mt-5"></div>
            </Col>

            <Col xxl={10} xl={12} className="mt-5">
              <RulePrize />
            </Col>

            <Col xxl={10} xl={12} className="mt-5">
              <CodeOfConduct />
            </Col>
          </Row>
        </Container>

        <div className="mt-5" style={{ overflow: `hidden` }}>
          <div style={{ paddingTop: `3rem` }}>
            <Row
              justify="center"
              style={{ alignItems: `center`, backgroundColor: `#170f1e` }}
            >
              <Col sm={10} md={10} lg={6}>
                <EventMap />
              </Col>
              <Col sm={10} md={10} lg={4}>
                <div className="wmt_white_logo">
                  <WMTLogo />
                  <div className="text-center mt-15">
                    <h4
                      className="text-white title-fonts"
                      style={{ lineHeight: `24px` }}
                    >
                      202, Kalasagar Shopping Hub, <br /> Sattadhar Cross Rd,
                      Ghatlodiya,
                      <br /> Ahmedabad, Gujarat 380061
                    </h4>
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                      <button
                        className="register-btn button btn-special"
                        onClick={() => redirectMap()}
                      >
                        Directions
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="mt-5 riot">
          <div>
            <Container>
              <Row justify="center" style={{ alignItems: `center` }}>
                <Col xxl={10} xl={12}>
                  <h2 className="title is-2 is-spaced register">
                    {` `}
                    Have a Riot
                  </h2>
                </Col>
              </Row>
              <Row className="happymoment" id="happymoment">
                <Col xxl={10} xl={12}>
                  <HappyMoment data={props.data.allFile.edges} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="mt-5 spread">
          <div style={{ paddingTop: `3rem`, paddingBottom: `3rem` }}>
            <Container>
              <Row justify="center" style={{ alignItems: `center` }}>
                <Col xxl={10} xl={12}>
                  <div className="divider_curve_main">
                    <div className="divider-three">
                      <div className="garnish garnish-short"></div>
                      <div className="garnish garnish-short"></div>
                      <div className="garnish garnish-long"></div>
                    </div>
                    <div className="long"></div>
                    <div className="angled_wrapper">
                      <div className="angled"></div>
                    </div>
                    <div className="short"></div>
                  </div>
                </Col>
                <Col sm={4}>
                  <h2 className="title is-2 is-spaced title-fonts">
                    {` `}
                    Spread the word!
                  </h2>

                  <button className="btn-special btn-social register-btn">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https://hacktoberfest.webmobtech.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="align-center"
                    >
                      <span>
                        <svg
                          width="36"
                          height="31"
                          viewBox="0 0 19 37"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.41406 36.5H12.0234V20.3984H17.1562L18 14H12.0234V9.57031C12.0234 8.58594 12.1641 7.8125 12.5859 7.32031C13.0078 6.75781 13.9219 6.47656 15.1875 6.47656H18.5625V0.78125C17.2969 0.640625 15.6094 0.5 13.6406 0.5C11.1094 0.5 9.14062 1.27344 7.66406 2.75C6.11719 4.22656 5.41406 6.26562 5.41406 8.9375V14H0V20.3984H5.41406V36.5Z"
                            fill="black"
                          ></path>
                        </svg>
                      </span>
                      <span>Facebook</span>
                    </a>
                  </button>
                  <button className="btn-special btn-social register-btn">
                    <a
                      href="https://ctt.ac/4xLO9"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="align-center"
                    >
                      <svg
                        width="36"
                        height="31"
                        viewBox="0 0 36 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.2734 8.1875C33.6797 7.13281 34.9453 5.86719 36 4.32031C34.5938 4.95312 33.1875 5.375 31.7812 5.51562C33.3281 4.53125 34.4531 3.19531 35.0156 1.4375C33.5391 2.28125 31.9922 2.91406 30.3047 3.19531C29.6016 2.49219 28.7578 1.92969 27.8438 1.50781C26.9297 1.08594 25.9453 0.875 24.8906 0.875C23.5547 0.875 22.3594 1.22656 21.2344 1.85938C20.1094 2.5625 19.1953 3.47656 18.5625 4.60156C17.8594 5.72656 17.5781 6.99219 17.5781 8.25781C17.5781 8.82031 17.5781 9.38281 17.7188 9.94531C14.6953 9.80469 11.8828 9.10156 9.21094 7.69531C6.53906 6.35938 4.35938 4.53125 2.53125 2.21094C1.82812 3.40625 1.47656 4.67188 1.47656 5.9375C1.47656 7.20312 1.75781 8.39844 2.39062 9.45312C2.95312 10.5781 3.79688 11.4219 4.78125 12.125C3.58594 12.125 2.46094 11.7734 1.47656 11.1406V11.2812C1.47656 13.0391 2.03906 14.5859 3.16406 15.9219C4.28906 17.3281 5.69531 18.1719 7.38281 18.5234C6.67969 18.6641 6.04688 18.7344 5.41406 18.7344C4.99219 18.7344 4.5 18.7344 4.07812 18.6641C4.5 20.1406 5.34375 21.3359 6.60938 22.3203C7.875 23.3047 9.28125 23.7266 10.9688 23.7266C8.22656 25.8359 5.13281 26.8906 1.75781 26.8906C1.05469 26.8906 0.492188 26.8906 0 26.8203C3.375 29.0703 7.17188 30.125 11.3203 30.125C15.6094 30.125 19.4062 29.0703 22.7812 26.8203C25.8047 24.8516 28.1953 22.25 29.8828 18.875C31.5 15.7812 32.3438 12.4766 32.3438 9.10156C32.3438 8.67969 32.2734 8.39844 32.2734 8.1875Z"
                          fill="black"
                        ></path>
                      </svg>
                      Twitter
                    </a>
                  </button>
                </Col>
                <Col sm={6}>
                  <div className="logo_wrapper">
                    <div className="gcBapg">
                      <div className="h-mark_wrapper">
                        <svg
                          width="52"
                          height="60"
                          viewBox="0 0 47 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-mark"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M36.5556 2.73456e-06L31.3333 0V5.1H26.1111L26.1111 10.2L20.8889 10.2V15.3H26.1111L26.1111 10.2L31.3333 10.2L31.3333 25.5L20.8889 25.5V30.6L15.6667 30.6V5.1L10.4444 5.1V10.2L5.22222 10.2V15.3L1.30258e-06 15.3L0 20.4H5.22222V15.3H10.4444L10.4444 35.7L5.22222 35.7L5.22222 40.8H10.4444L10.4444 51L15.6667 51L15.6667 35.7L20.8889 35.7L20.8889 30.6L31.3333 30.6L31.3333 45.9L36.5556 45.9L36.5556 40.8H41.7778V35.7L47 35.7V30.6L41.7778 30.6L41.7778 35.7H36.5556L36.5556 2.73456e-06Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                      <div className="logo_globalWrapper" id="globe">
                        <svg
                          width="164"
                          height="164"
                          viewBox="0 0 104 104"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M103.494 59.28C103.828 56.9012 104 54.4707 104 52C104 44.4439 102.388 37.2642 99.4901 30.786C99.0711 29.8494 98.6253 28.9275 98.1535 28.0212C89.485 11.3705 72.0703 0 52 0C23.2812 0 0 23.2812 0 52C0 80.7188 23.2812 104 52 104C75.3774 104 95.1517 88.5737 101.699 67.3453C102.441 64.9397 103.013 62.4596 103.401 59.9191C103.433 59.7065 103.465 59.4935 103.494 59.28ZM99.0506 65.7291C100.32 61.3728 101 56.7659 101 52C101 45.0711 99.5618 38.4781 96.9677 32.5032C94.7544 33.8552 92.1735 35.0836 89.3025 36.1746C79.6824 39.8302 66.4937 42.06 52.0001 42.06C37.5065 42.06 24.3177 39.8302 14.6977 36.1746C11.8266 35.0836 9.2457 33.8552 7.03232 32.5031C4.43818 38.478 3 45.0711 3 52C3 56.7659 3.68039 61.3728 4.94935 65.729C4.99539 65.7712 5.04192 65.8135 5.08893 65.8557C7.53451 68.0529 11.1579 70.1002 15.7633 71.8503C24.9636 75.3464 37.7748 77.54 52 77.54C66.2253 77.54 79.0365 75.3464 88.2368 71.8503C92.8422 70.1002 96.4656 68.0529 98.9111 65.8557C98.9581 65.8135 99.0046 65.7713 99.0506 65.7291ZM97.2637 70.8002C94.9856 72.2246 92.3025 73.5146 89.3024 74.6546C79.6824 78.3102 66.4936 80.54 52 80.54C37.5065 80.54 24.3177 78.3102 14.6977 74.6546C11.6975 73.5146 9.01438 72.2245 6.73623 70.8002C14.1095 88.5321 31.5987 101 52 101C72.4012 101 89.8905 88.5321 97.2637 70.8002ZM88.2368 33.3703C91.1183 32.2753 93.6154 31.0639 95.6801 29.7723C87.5779 13.8822 71.0598 3 52 3C32.9402 3 16.4221 13.8821 8.31992 29.7722C10.3846 31.0639 12.8818 32.2753 15.7634 33.3703C24.9636 36.8664 37.7749 39.06 52.0001 39.06C66.2253 39.06 79.0365 36.8664 88.2368 33.3703Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <div className="logo-styleDiv">
                          <div className="spinner"></div>
                        </div>
                        <div className="logo-div">
                          <div className="spinner"></div>
                        </div>
                      </div>
                    </div>
                    <p>Hacktoberfest</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="footer-end">
          <p className="footer-text">
            © 2022 DigitalOcean, LLC. All rights reserved.
          </p>
          <div className="socials">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/2014545"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-gradient-color"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/webmobtech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/webmobtechnologies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/webmobtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query MyQuery {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "lastyear" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(formats: PNG)
          }
        }
      }
    }
  }
`;

export default IndexPage;
