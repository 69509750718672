import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './happymoment.css';
import React from 'react';

function HappyMoment(props: any) {
  const settings = {
    className: `center`,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8500,
    dots: false,
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { data } = props;
  return (
    <>
      {/* <div>shailja</div> */}
      <Slider {...settings}>
        {data.map((item: any, index: any) => {
          const img = getImage(item.node);
          return (
            <div className="img-wrapper" key={index}>
              <GatsbyImage className="img-fluid" image={img} alt="all well" />
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default HappyMoment;
