import React from 'react';
import Logo from '../../../static/images/wmt_white_logo.png';

const WMTLogo = () => {
  return (
    <a
      className="resp_logo"
      href="https://webmobtech.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={Logo} alt="WMT Logo" />
    </a>
  );
};

export default WMTLogo;
