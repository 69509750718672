import React from 'react';

const HacktoberFest = () => (
  <div>
    <div className="divider_curve_main mt-9">
      <div className="divider-three">
        <div className="garnish garnish-short"></div>
        <div className="garnish garnish-short"></div>
        <div className="garnish garnish-long"></div>
      </div>
      <div className="long"></div>
      <div className="angled_wrapper">
        <div className="angled"></div>
      </div>
      <div className="short"></div>
    </div>

    <a className="number">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H22C22.8284 0.5 23.5 1.17157 23.5 2V22C23.5 22.8284 22.8284 23.5 22 23.5H8.82843C8.4306 23.5 8.04907 23.342 7.76777 23.0607L0.939339 16.2322C0.658035 15.9509 0.5 15.5694 0.5 15.1716V2Z"
          stroke="currentColor"
        ></path>
      </svg>
    </a>

    <h2 className="title is-2 is-spaced register">
      What&apos;s Hacktoberfest?
    </h2>
    <p className="p text-normal">
      <a
        href="https://hacktoberfest.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Hacktoberfest — brought to you by DigitalOcean
      </a>
      {` `}— is a month-long celebration of open source software. Maintainers
      are invited to guide would-be contributors toward issues that will help
      move the project forward, and contributors get the opportunity to give
      back to both projects they like and others they&apos;ve just discovered.
      No contribution is too small — bug fixes and documentation updates are
      valid ways of participating.
    </p>
    <p className="p text-normal">
      This year,{` `}
      <a
        href="https://webmobtech.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        WebMob Technologies
      </a>
      {` `}
      will hold the fourth official Hacktoberfest event in Ahmedabad.{` `}
      <strong className="title-shadow">
        The event takes place on October 14.
      </strong>
    </p>

    <p className="p text-normal">
      Can&apos;t make it to this event? Hacktoberfest is virtual and open to
      participants from around the globe. Sign up to participate today at{` `}
      <a
        href="https://hacktoberfest.com"
        target="_blank"
        rel="noopener noreferrer"
        className="overflow-break"
      >
        https://hacktoberfest.com
      </a>
      .
    </p>
    <div className="text-normal">
      Just like Hacktoberfest loves open source projects. We webmob Technologies
      love and care about open source projects, the community behind it and
      encourage others for doing the same
    </div>
  </div>
);

export default HacktoberFest;
