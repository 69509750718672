import React from 'react';
import GoogleMapReact from 'google-map-react';
import mapstyle from './mapStyle';
import wmtMapIcon from './maker.png';

const Marker = ({ icon }) => (
  <div>
    <img
      src={icon}
      style={{
        height: 40,
        filter: `invert(37%) sepia(64%) saturate(5147%) hue-rotate(221deg) brightness(98%) contrast(107%)`,
      }}
      alt=" "
    />
  </div>
);

function EventMap(props: any) {
  const mapCenter = {
    lat: 23.06637,
    lng: 72.5317,
  };
  return (
    <div style={{ height: `60vh`, width: `100%` }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `AIzaSyBI6bQXwNHT0nmQyhdqHFM4s42wInRjKKc` }}
        defaultCenter={mapCenter}
        defaultZoom={props.zoom}
        options={{
          styles: [...mapstyle],
          zoomControl: false,
          streetViewControl: false,
          panControl: false,
          scrollwheel: false,
        }}
      >
        <Marker lat={mapCenter.lat} lng={mapCenter.lng} icon={wmtMapIcon} />
      </GoogleMapReact>
    </div>
  );
}
EventMap.defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 17,
};
export default EventMap;
